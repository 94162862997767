<template>
  <div class="Faq">
    <div class="banner">
      <div class="container">
      </div>
    </div>
    <div class="cont pd40">
      <div class="container">
        <div class="headTit">
          <h3>The <span>Scholarship</span></h3>
        </div>
        <div class="_neiNav">
          <div class="lis"
               v-for="(item,index) in navList"
               :key="index"
               :class="showFlag == index ? 'on':'' "
               @click="onShows(index,item.id)">{{item.title}}</div>
        </div>
        <div style="position: relative; min-height:300px;">
          <div class="loadingCont"
               v-if="showLoading">
            <Loading class="lii"></Loading>
          </div>
          <transition name="el-fade-in-linear">
            <div v-if="!showLoading">
              <div class="newsList">
                <div class="lis"
                     v-if="showFlag == 0 && showpg">
                  <el-row :gutter="20">
                    <el-col :span="18">
                      <div class="txt">
                        <h3>Introduction to Chinese Government Scholarships</h3>
                        <div class="_p">In order to promote the mutual understanding, cooperation and exchanges in various fields between China and other
                          countries, the Chinese government has set up a series of scholarship programs to sponsor international students.
                          teac hers and scholars to study and conduct research in chinese universities</div>
                        <router-link class="toPath"
                                     to="/scholarship-detail-1">View more+</router-link>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div class="imgbox">
                        <img src="@/assets/ccmt1.jpg"
                             alt="">
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div class="lis"
                     v-for="(item,index) in list"
                     :key="index">
                  <el-row :gutter="20">
                    <el-col :span="18">
                      <div class="txt">
                        <h3>{{item.title}}</h3>
                        <div class="_p">
                          {{item.description}}
                        </div>
                        <router-link class="toPath"
                                     :to="'/news-detail/'+item.id">View more+</router-link>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div class="txt">
                        <h3>Scholarship University</h3>
                        <div class="edulist">
                          <router-link class="edu"
                                       :to="'/school-detail/'+item2.link"
                                       v-for="(item2,index) in item.school"
                                       :key="index"><span>{{item2.university}}</span></router-link>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div style="margin-top:20px;">
                  <el-pagination v-if="showpg"
                                 background
                                 layout="prev, pager, next"
                                 @next-click="onNextPage"
                                 @prev-click="onPrevPage"
                                 @current-change="onCurrentChange"
                                 :page-size="pageSize"
                                 :total="total">
                  </el-pagination>
                </div>
              </div>
            </div>
          </transition>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axiosApi from "../api/api.js";
import { ref } from "vue";
import Loading from '@/components/LoadingBox'

export default {
  components: {
    Loading
  },
  data () {
    return {

    }
  },
  setup () {
    const list = ref()
    const showFlag = ref(0)
    const navList = ref()
    const total = ref(2)
    const pageSize = ref(12)
    const showpg = ref(false)
    const listId = ref()
    const showLoading = ref(true)
    const onShows = (index, id) => {
      list.value = []
      listId.value = id
      showpg.value = false
      showLoading.value = true
      showFlag.value = index
      axiosApi(
        'news/' + listId.value, {}, 'get'
      ).then((res) => {
        showLoading.value = false
        if (res.data.data.length > 0) {
          showpg.value = true
          list.value = res.data.data
          total.value = res.data.total
          pageSize.value = res.data.per_page
        }
      })
    }

    const onCurrentChange = (val) => {
      showLoading.value = false
      axiosApi(
        'news/' + listId.value + '?page=' + val, {}, 'get'
      ).then((res) => {
        showLoading.value = true
        list.value = res.data.data
        total.value = res.data.total
        pageSize.value = res.data.per_page
      })
    }
    const onNextPage = (val) => {
      showLoading.value = false
      axiosApi(
        'news/' + listId.value + '?page=' + val, {
      }, 'get'
      ).then((res) => {
        showLoading.value = true
        list.value = res.data.data
        total.value = res.data.total
        pageSize.value = res.data.per_page
      })
    }
    const onPrevPage = (val) => {
      showLoading.value = false
      axiosApi(
        'news/' + listId.value + '?page=' + val, {
      }, 'get'
      ).then((res) => {
        showLoading.value = true
        list.value = res.data.data
        total.value = res.data.total
        pageSize.value = res.data.per_page
      })
    }
    return {
      list,
      showFlag,
      onShows,
      navList,
      onCurrentChange,
      onNextPage,
      onPrevPage,
      total,
      pageSize,
      showpg,
      showLoading
    }
  },
  beforeMount () {
    axiosApi(
      'cate/5', {}, 'get'
    ).then((res) => {
      this.navList = res.data
    })
    axiosApi(
      'news/12', {}, 'get'
    ).then((res) => {
      this.showLoading = false
      this.showpg = true
      this.list = res.data.data
      this.total = res.data.total
      this.pageSize = res.data.per_page
    })
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/neibanner1.jpg) top center no-repeat;
  height: 400px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}

._neiNav {
  display: flex;
  .lis {
    width: 16.6666%;
    background-color: #0c3879;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    border-right: 1px solid #dedede;
    cursor: pointer;
    &:last-child {
      border-right: none;
    }
    &.on,
    &:hover {
      background-color: #5ca3df;
    }
  }
}
.newsList {
  .lis {
    border: 1px solid #dedede;
    padding: 5px;
    margin-top: 20px;
    transition: ease 0.5s;
    .txt {
      padding: 10px;
    }
    h3 {
      transition: ease 0.5s;
    }
    ._p {
      margin-top: 20px;
      color: #545454;
      transition: ease 0.5s;
    }
    .toPath {
      margin-top: 20px;
      display: block;
      width: max-content;
      padding: 5px 20px;
      text-align: center;
      color: #787878;
      border: 1px solid #dedede;
      transition: ease 0.5s;
      &:hover {
        background-color: #55a1e4;
        color: #fff;
      }
    }

    .edulist {
      margin-top: 20px;
      .edu {
        display: flex;
        align-items: center;
        line-height: 1;
        margin-bottom: 10px;
        transition: ease 0.5s;
        span {
          width: calc(100% - 10px);
        }
        &::before {
          content: "";
          width: 5px;
          height: 5px;
          background-color: #ffc107;
          display: inline-block;
          margin-right: 5px;
        }
        &:hover {
          color: #ffc107;
        }
      }
    }
    &:hover {
      background-color: #163779;
      border-color: #163779;
      h3,
      ._p {
        color: #fff;
      }
      a {
        color: #fff;
      }
      .edu {
        color: #fff;
      }
    }
  }
}
.loadingCont {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  padding-top: 100px;
}
</style>