<template>
  <div class="LoaingBox">
    <div class="lii">
      <el-icon class="is-loading"
               :size='30'>
        <Loading />
      </el-icon>
      <p>Loading</p>
    </div>
  </div>
</template>

<script>
import { Loading } from '@element-plus/icons'

export default {
  name: 'LoaingBox',
  components: {
    Loading
  },
  props: {
  },
  data () {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
.lii {
  text-align: center;
  color: #919191;
}
</style>